import React from 'react'
import { Check, X } from 'lucide-react'

const ComparisonSection = () => {
    const competitors = [
        {
            name: 'PUBLICGRANTS.ORG',
            governmentGrants: true,
            matching: true,
            assistance: true,
            personalGrants: true,
            businessGrants: true,
            noLoans: true,
            pricing: '$15/mo',
            highlight: true,
        },
        {
            name: 'GRANTS.GOV',
            governmentGrants: false,
            matching: false,
            assistance: false,
            personalGrants: false,
            businessGrants: true,
            noLoans: false,
            pricing: '$0/mo',
        },
        {
            name: 'HELLOSKIP',
            governmentGrants: true,
            matching: true,
            assistance: true,
            personalGrants: false,
            businessGrants: true,
            noLoans: false,
            pricing: '$119.88*',
        },
        {
            name: 'INSTRUMENTL',
            governmentGrants: true,
            matching: true,
            assistance: true,
            personalGrants: false,
            businessGrants: false,
            noLoans: false,
            pricing: '$179/mo',
        },
        {
            name: 'GRANTWATCH',
            governmentGrants: true,
            matching: false,
            assistance: false,
            personalGrants: true,
            businessGrants: true,
            noLoans: false,
            pricing: '$45/mo',
        },
        {
            name: 'USAFUNDINGAPPLICATIONS',
            governmentGrants: true,
            matching: false,
            assistance: false,
            personalGrants: true,
            businessGrants: true,
            noLoans: false,
            pricing: '$34.95/mo',
        },
    ]

    const features = [
        {
            title: 'Lists government, corporate & private grants',
            key: 'governmentGrants',
        },
        {
            title: 'Matches you with relevant grants',
            key: 'matching',
        },
        {
            title: 'Assistance with writing applications',
            key: 'assistance',
        },
        {
            title: 'Grants for personal help',
            key: 'personalGrants',
        },
        {
            title: 'Grants for business help',
            key: 'businessGrants',
        },
        {
            title: 'DOES NOT promote loans',
            key: 'noLoans',
        },
    ]

    // Desktop view
    const DesktopTable = () => (
        <div className='hidden lg:block overflow-hidden rounded-lg shadow-lg bg-white'>
            <table className='w-full border-collapse'>
                <thead>
                    <tr className='bg-gray-100'>
                        <th className='border-b p-4 text-left font-medium text-gray-600'>
                            Features
                        </th>
                        {competitors.map((comp) => (
                            <th
                                key={comp.name}
                                className={`border-b p-4 text-center font-bold ${
                                    comp.highlight
                                        ? 'bg-blue-50 text-blue-600'
                                        : 'text-gray-900'
                                }`}
                            >
                                {comp.name}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {features.map((feature, index) => (
                        <tr
                            key={feature.key}
                            className={
                                index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                            }
                        >
                            <td className='border-b p-4 text-gray-700'>
                                {feature.title}
                            </td>
                            {competitors.map((comp) => (
                                <td
                                    key={comp.name}
                                    className={`border-b p-4 text-center ${
                                        comp.highlight ? 'bg-blue-50' : ''
                                    }`}
                                >
                                    {comp[feature.key] ? (
                                        <Check className='inline-block text-green-500 w-5 h-5' />
                                    ) : (
                                        <X className='inline-block text-red-500 w-5 h-5' />
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                    <tr>
                        <td className='border-b p-4 font-medium text-gray-700'>
                            Pricing
                        </td>
                        {competitors.map((comp) => (
                            <td
                                key={comp.name}
                                className={`border-b p-4 text-center font-medium ${
                                    comp.highlight
                                        ? 'bg-blue-50 text-blue-600'
                                        : 'text-gray-900'
                                }`}
                            >
                                {comp.pricing}
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    )

    // Mobile view - Card layout
    const MobileCards = () => (
        <div className='lg:hidden space-y-6'>
            {competitors.map((comp) => (
                <div
                    key={comp.name}
                    className={`rounded-lg shadow-md overflow-hidden ${
                        comp.highlight
                            ? 'border-2 border-blue-500'
                            : 'border border-gray-200'
                    }`}
                >
                    <div
                        className={`p-4 ${
                            comp.highlight ? 'bg-blue-50' : 'bg-white'
                        }`}
                    >
                        <h3
                            className={`text-lg font-bold mb-2 ${
                                comp.highlight
                                    ? 'text-blue-600'
                                    : 'text-gray-900'
                            }`}
                        >
                            {comp.name}
                        </h3>
                        <div className='text-xl font-bold text-gray-900 mb-4'>
                            {comp.pricing}
                        </div>
                        <div className='space-y-3'>
                            {features.map((feature) => (
                                <div
                                    key={feature.key}
                                    className='flex items-center justify-between'
                                >
                                    <span className='text-sm text-gray-600'>
                                        {feature.title}
                                    </span>
                                    <span>
                                        {comp[feature.key] ? (
                                            <Check className='text-green-500 w-5 h-5' />
                                        ) : (
                                            <X className='text-red-500 w-5 h-5' />
                                        )}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )

    return (
        <section className='max-w-7xl mx-auto px-4 py-16 bg-gray-50'>
            <div className='max-w-3xl mx-auto text-center mb-12'>
                <h2 className='text-3xl font-bold text-gray-900 mb-4'>
                    Compare Us to Others
                </h2>
                <p className='text-gray-600'>
                    See how our features stack up against other grant platforms
                </p>
            </div>

            <DesktopTable />
            <MobileCards />

            <div className='mt-4 text-sm text-gray-500 text-center'>
                *($9.99/mo billed annually)
            </div>
        </section>
    )
}

export default ComparisonSection
