import React from 'react'
import { Facebook, Twitter, Linkedin, Instagram } from 'lucide-react'

const FooterSection = ({ title, links }) => (
    <div className='relative'>
        <h3 className="text-lg font-bold text-gray-800 mb-6 after:content-[''] after:absolute after:left-0 after:-bottom-2 after:w-12 after:h-1 after:bg-blue-600">
            {title}
        </h3>
        <div className='space-y-3'>
            {links.map((link, index) => (
                <a
                    key={index}
                    href={link.href}
                    className='block text-gray-600 hover:text-blue-600 transition-colors duration-200 text-sm'
                >
                    {link.label}
                </a>
            ))}
        </div>
    </div>
)

const SocialIcon = ({ icon: Icon, href, label }) => (
    <a
        href={href}
        className='p-2 text-gray-600 hover:text-blue-600 hover:bg-blue-50 rounded-full transition-all duration-200'
        aria-label={label}
    >
        <Icon className='h-5 w-5' />
    </a>
)

const Footer = () => {
    const sections = {
        about: {
            title: 'About',
            links: [
                { href: '#', label: 'About Us' },
                { href: '#', label: 'Careers' },
                { href: '#', label: 'Grants' },
                { href: '#', label: 'Memberships' },
                { href: '#', label: 'Success Stories' },
            ],
        },
        resources: {
            title: 'Resources',
            links: [
                { href: '#', label: 'Blog' },
                { href: '#', label: 'Partner With Us' },
                { href: '#', label: 'Grant Funding Guide' },
                { href: '#', label: 'Latest Grant Options' },
                { href: '#', label: 'Submit a Grant' },
            ],
        },
        support: {
            title: 'Support',
            links: [
                { href: '#', label: 'Help Center' },
                { href: '#', label: 'Contact Us' },
                { href: '#', label: 'Privacy Policy' },
                { href: '#', label: 'Terms of Service' },
            ],
        },
    }

    return (
        <footer className='bg-white border-t border-gray-100'>
            <div className='max-w-7xl mx-auto px-8 py-16'>
                <div className='grid grid-cols-1 md:grid-cols-4 gap-12'>
                    {Object.values(sections).map((section, index) => (
                        <FooterSection key={index} {...section} />
                    ))}

                    <div>
                        <h3 className='text-lg font-bold text-gray-800 mb-6'>
                            Follow Us
                        </h3>
                        <div className='flex space-x-2'>
                            <SocialIcon
                                href='#'
                                label='Twitter'
                                icon={Twitter}
                            />
                            <SocialIcon
                                href='#'
                                label='Facebook'
                                icon={Facebook}
                            />
                            <SocialIcon
                                href='#'
                                label='LinkedIn'
                                icon={Linkedin}
                            />
                            <SocialIcon
                                href='#'
                                label='Instagram'
                                icon={Instagram}
                            />
                        </div>
                    </div>
                </div>

                <div className='mt-16 pt-8 border-t border-gray-100'>
                    <div className='text-center'>
                        <p className='text-sm text-gray-400'>
                            &copy; {new Date().getFullYear()} PublicGrants. All
                            rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
