import React, { useState } from 'react'
import { Menu } from 'lucide-react'

const NavLink = ({ href, children }) => (
    <a
        href={href}
        className='text-gray-700 hover:text-gray-900 px-3 py-2 text-sm font-medium'
    >
        {children}
    </a>
)

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)

    return (
        <nav className='bg-white shadow-sm'>
            <div className='max-w-7xl mx-auto px-4'>
                <div className='flex justify-between h-16 items-center'>
                    <div className='flex-shrink-0'>
                        <span className='text-2xl font-bold text-blue-600'>
                            PublicGrants
                        </span>
                    </div>

                    <div className='hidden md:flex items-center space-x-4'>
                        <NavLink href='#'>Search Grants</NavLink>
                        <NavLink href='#'>Explore Memberships</NavLink>
                        <NavLink href='#'>Get Help</NavLink>
                        <button className='px-4 py-2 text-blue-600 border border-blue-600 rounded-full hover:bg-blue-50'>
                            Login
                        </button>
                        <button className='px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700'>
                            Sign Up Free
                        </button>
                    </div>

                    <button
                        className='md:hidden'
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        aria-label='Toggle menu'
                    >
                        <Menu className='h-6 w-6 text-gray-600' />
                    </button>
                </div>
            </div>

            {/* Mobile menu */}
            {isMenuOpen && (
                <div className='md:hidden'>
                    <div className='px-2 pt-2 pb-3 space-y-1'>
                        <NavLink href='#'>Search Grants</NavLink>
                        <NavLink href='#'>Explore Memberships</NavLink>
                        <NavLink href='#'>Get Help</NavLink>
                        <div className='px-2 space-y-2'>
                            <button className='w-full px-4 py-2 text-blue-600 border border-blue-600 rounded-full'>
                                Login
                            </button>
                            <button className='w-full px-4 py-2 bg-blue-600 text-white rounded-full'>
                                Sign Up Free
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    )
}

export default Header
