import React, { useState, useEffect } from 'react'

const HeroSection = () => {
    const [currentText, setCurrentText] = useState(0)
    const [isLeaving, setIsLeaving] = useState(false)
    const heroTexts = ['Businesses', 'Individuals', 'Non-Profits']

    useEffect(() => {
        const interval = setInterval(() => {
            setIsLeaving(true)

            setTimeout(() => {
                setCurrentText((prev) => (prev + 1) % heroTexts.length)
                setIsLeaving(false)
            }, 500)
        }, 3000)

        return () => clearInterval(interval)
    }, [])

    return (
        <div className='relative overflow-hidden bg-gradient-to-br from-white to-blue-50'>
            <div className='absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,white,rgba(255,255,255,0.6))]' />

            <div className='relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24'>
                <div className='grid lg:grid-cols-2 gap-12 items-center'>
                    {/* Left Column */}
                    <div className='space-y-8'>
                        <div className='space-y-4'>
                            <h1 className='text-4xl sm:text-5xl lg:text-6xl font-extrabold text-gray-900 tracking-tight'>
                                <span className='block'>Public Grants For</span>
                                <div className='h-20 sm:h-24 relative mt-2'>
                                    <span
                                        className={`
                      absolute left-0 text-blue-600
                      transform transition-all duration-500 ease-in-out
                      ${
                          isLeaving
                              ? 'opacity-0 -translate-y-8'
                              : 'opacity-100 translate-y-0'
                      }
                    `}
                                    >
                                        {heroTexts[currentText]}
                                    </span>
                                </div>
                            </h1>
                        </div>

                        <p className='text-xl sm:text-2xl text-gray-600 max-w-2xl'>
                            Every grant opportunity, in one place.
                            <span className='block mt-2'>
                                <span className='bg-blue-100 px-2 py-1 rounded-md text-blue-800 font-semibold animate-pulse'>
                                    Search. Find. Apply.
                                </span>
                            </span>
                        </p>
                    </div>

                    {/* Right Column */}
                    <div className='relative'>
                        <div className='relative z-10 bg-white rounded-2xl shadow-xl p-8 backdrop-blur-sm bg-opacity-90'>
                            <div className='space-y-6'>
                                <p className='text-xl text-gray-600'>
                                    Find and apply for grants tailored to your
                                    needs. Our platform connects you with
                                    opportunities across all sectors.
                                </p>

                                <div className='border-l-4 border-blue-600 pl-4 py-2'>
                                    <p className='text-gray-500 font-medium'>
                                        We DO NOT promote loans, credit cards,
                                        or cash advances.
                                    </p>
                                </div>

                                <button className='w-full sm:w-auto px-8 py-4 bg-blue-600 text-white text-lg font-semibold rounded-xl hover:bg-blue-700 transform transition-all duration-300 hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'>
                                    Get Started
                                </button>
                            </div>
                        </div>

                        {/* Decorative elements */}
                        <div className='absolute -top-4 -right-4 w-72 h-72 bg-blue-100 rounded-full mix-blend-multiply filter blur-2xl opacity-70' />
                        <div className='absolute -bottom-4 -left-4 w-72 h-72 bg-blue-200 rounded-full mix-blend-multiply filter blur-2xl opacity-70' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSection
