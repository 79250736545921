import React from 'react'

const FundingCards = () => {
    const businessGrants = [
        {
            href: '#',
            title: 'FedEx Small Business Grant',
            description:
                'FedEx Small Business Grant offering support for small businesses',
            dueDate: 'April 1',
            amount: '$50,000',
            type: 'business grant',
        },
        {
            href: '#',
            title: "Amber Women's Grant",
            description: 'Monthly grant opportunity for women-owned businesses',
            dueDate: 'Monthly',
            amount: '$10,000',
            type: 'business grant',
        },
        {
            href: '#',
            title: 'Verizon Small Business Grants',
            description: 'Grant program supporting small business development',
            dueDate: 'December 13',
            amount: '$10,000',
            type: 'business grant',
        },
        {
            href: '#',
            title: 'Visa Everywhere Initiative',
            description: 'Initiative supporting innovative businesses',
            dueDate: 'December 31',
            amount: '$100,000',
            type: 'business grant',
        },
        {
            href: '#',
            title: 'Amazon Small Business Grants',
            description: 'Amazon grant program for small businesses',
            dueDate: 'May 24',
            amount: '$25,000',
            type: 'business grant',
        },
    ]

    const individualGrants = [
        {
            href: '#',
            title: 'LIHEAP',
            description: 'Helps cover heating and cooling costs',
            dueDate: 'Varies by state',
            amount: '$3,000',
            type: 'individual grant',
        },
        {
            href: '#',
            title: 'Lifeline Program',
            description: 'Offers free cell phone service',
            dueDate: 'Rolling',
            amount: 'Varies',
            type: 'individual grant',
        },
        {
            href: '#',
            title: 'SNAP',
            description: 'Free debit card to use towards groceries',
            dueDate: 'Varies by state',
            amount: '$187 per month avg.',
            type: 'individual grant',
        },
        {
            href: '#',
            title: 'Child Care and Development Fund',
            description: 'Money to help pay for child care',
            dueDate: 'July 1',
            amount: 'Depends on income',
            type: 'individual grant',
        },
        {
            href: '#',
            title: 'Pell Grant',
            description: 'Money for college',
            dueDate: 'June 30',
            amount: '$7,395',
            type: 'individual grant',
        },
    ]

    return (
        <div className='w-full bg-gray-50'>
            <div className='max-w-7xl mx-auto px-4 py-8'>
                {/* Business Grants Section */}
                <section className='mb-12'>
                    <h2 className='text-2xl font-bold text-gray-900 mb-6 px-2'>
                        Business Grants
                    </h2>
                    <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4'>
                        {businessGrants.map((grant, index) => (
                            <div
                                key={`business-${index}`}
                                className='bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200 overflow-hidden flex flex-col'
                            >
                                <div className='h-40 bg-gradient-to-br from-blue-50 to-blue-100 p-6 flex items-center justify-center'>
                                    <span className='text-2xl font-bold text-blue-600'>
                                        {grant.amount}
                                    </span>
                                </div>
                                <div className='p-4 flex-1 flex flex-col'>
                                    <h3 className='font-semibold text-base mb-2 text-gray-900'>
                                        {grant.title}
                                    </h3>
                                    <p className='text-gray-600 text-xs'>
                                        {grant.description}
                                    </p>
                                    <div className='mt-auto pt-4'>
                                        <div className='bg-blue-100 text-blue-800 px-3 py-2 rounded-lg w-full text-center'>
                                            <span className='text-xs font-semibold'>
                                                Due:{' '}
                                                <b className='underline'>
                                                    {grant.dueDate}
                                                </b>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>

                {/* Individual Grants Section */}
                <section>
                    <h2 className='text-2xl font-bold text-gray-900 mb-6 px-2'>
                        Individual Grants
                    </h2>
                    <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4'>
                        {individualGrants.map((grant, index) => (
                            <div
                                key={`individual-${index}`}
                                className='bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200 overflow-hidden flex flex-col'
                            >
                                <div className='h-40 bg-gradient-to-br from-green-50 to-green-100 p-6 flex items-center justify-center'>
                                    <span className='text-2xl font-bold text-green-600'>
                                        {grant.amount}
                                    </span>
                                </div>
                                <div className='p-4 flex-1 flex flex-col'>
                                    <h3 className='font-semibold text-base mb-2 text-gray-900'>
                                        {grant.title}
                                    </h3>
                                    <p className='text-gray-600 text-xs'>
                                        {grant.description}
                                    </p>
                                    <div className='mt-auto pt-4'>
                                        <div className='bg-green-100 text-green-800 px-3 py-2 rounded-lg w-full text-center'>
                                            <span className='text-xs font-semibold'>
                                                Due:{' '}
                                                <b className='underline'>
                                                    {grant.dueDate}
                                                </b>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </div>
        </div>
    )
}

export default FundingCards
