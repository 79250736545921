import React, { useEffect, useRef } from 'react'
import {
    ChevronDown,
    Search,
    FileText,
    MessageSquareText,
    UserCog,
} from 'lucide-react'

const HowItWorksSection = () => {
    const observerRef = useRef(null)

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate-in')
                    }
                })
            },
            { threshold: 0.1 }
        )

        const elements = document.querySelectorAll('.fade-in-section')
        elements.forEach((el) => observer.observe(el))

        observerRef.current = observer

        return () => observer.disconnect()
    }, [])

    const steps = [
        {
            title: 'Find Grant Opportunities',
            description:
                'Discover grant opportunities and gain valuable insights on applying effectively. PublicGrants.org provides information and applications for grants only. We DO NOT promote other sources of funding.',
            subtext:
                'Predatory lending hurts individuals and small businesses by promoting high interest loans to those searching for grant funding. Therefore we do not promote, partner or share your information with any loan service providers. We recommend individuals or businesses looking for a loan to contact their local bank, credit union or SBA.',
            icon: Search,
            align: 'left',
            color: 'bg-blue-500',
        },
        {
            title: 'Complete Applications Faster',
            description:
                'Effortlessly write compelling applications using AI and save them securely in your personalized Grants Vault, keeping you organized and focused on winning funding.',
            icon: FileText,
            align: 'right',
            color: 'bg-purple-500',
        },
        {
            title: 'Get Instant Feedback',
            description:
                'Receive instant feedback to refine your funding application in real-time and boost your chances of securing the funding you need.',
            icon: MessageSquareText,
            align: 'left',
            color: 'bg-green-500',
        },
        {
            title: 'Or Let Us Apply For You',
            description:
                'Save time by outsourcing your grant applications to us; we handle everything to maximize your chances of success.',
            icon: UserCog,
            align: 'right',
            color: 'bg-orange-500',
        },
    ]

    return (
        <div className='w-full min-w-full bg-gradient-to-b from-blue-50/30 to-white'>
            {/* Animated Header */}
            <div className='relative flex flex-col items-center w-full'>
                <ChevronDown className='w-10 h-10 text-blue-500 mt-8 animate-bounce' />
                <div
                    className='px-8 py-3 rounded-full relative -top-3 z-10 
                    shadow-lg border border-gray-100'
                >
                    <h2 className='text-2xl font-bold text-gray-800 tracking-wide'>
                        HOW IT WORKS
                    </h2>
                </div>
            </div>

            {/* Content Section */}
            <div className='w-full py-16 px-8'>
                <div className='w-full max-w-none mx-auto space-y-24'>
                    {steps.map((step, index) => (
                        <div
                            key={step.title}
                            className={`fade-in-section opacity-0 transition-all duration-1000 ease-out
                                flex flex-col ${
                                    step.align === 'right'
                                        ? 'md:flex-row-reverse'
                                        : 'md:flex-row'
                                } 
                                gap-12 items-center justify-center w-full ${
                                    step.align === 'right'
                                        ? 'md:justify-center'
                                        : ''
                                }`}
                        >
                            {/* Icon Container */}
                            <div className='relative w-[200px] h-[200px] flex-shrink-0'>
                                <div
                                    className={`absolute inset-0 ${step.color} opacity-10 rounded-2xl transform rotate-6`}
                                />
                                <div
                                    className='w-full h-full flex items-center justify-center 
                                    bg-white/50 backdrop-blur-sm border border-gray-100
                                    shadow-xl rounded-2xl overflow-hidden
                                    group hover:scale-105 transition-all duration-300'
                                >
                                    <step.icon
                                        className={`rounded p-4 w-20 h-20 ${step.color} text-white/90 
                                        transform transition-all duration-300
                                        group-hover:scale-110 group-hover:rotate-6`}
                                    />
                                </div>
                            </div>

                            {/* Content */}
                            <div className='flex flex-col gap-4 max-w-none w-full'>
                                <div className='space-y-2'>
                                    <h3 className='text-3xl font-bold text-gray-800'>
                                        {step.title}
                                    </h3>
                                    <div
                                        className={`h-1 w-24 rounded-full ${step.color}`}
                                    />
                                </div>
                                <p className='text-lg text-gray-600 leading-relaxed'>
                                    {step.description}
                                </p>
                                {step.subtext && (
                                    <p className='text-gray-500 leading-relaxed mt-2'>
                                        {step.subtext}
                                    </p>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HowItWorksSection
