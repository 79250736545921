import React from 'react'
import Header from './components/Header'
import HeroSection from './sections/HeroSection'
import FundingCards from './components/FundingCards'
import ComparisonSection from './components/ComparisonSection'
import Footer from './components/Footer'
import HowItWorksSection from './sections/HowItWorksSection'

const App = () => {
    return (
        <div className='min-h-screen bg-gradient-to-b from-blue-50/30 to-white'>
            {/* Header */}
            <Header />

            {/* Main Content */}
            <main>
                <HeroSection />

                <FundingCards />

                <HowItWorksSection />

                {/* Comparison Section */}
                <section className='py-12'>
                    <ComparisonSection />
                </section>
            </main>

            {/* Footer */}
            <Footer />
        </div>
    )
}

export default App
